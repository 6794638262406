<template>
  <div>
    <InvoiceUpload :account="selectedAccount" :is-user="true" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import InvoiceUpload from '@/components/InvoiceUpload';

export default {
  name: 'AssetAccountUpload',
  components: {
    InvoiceUpload
  },
  data() {
    return {
      form: {}
    };
  },
  computed: {
    ...mapGetters({
      selectedAccount: 'asset/selectedAccount',
      accountLoadingAction: 'account/loadingAction'
    })
  },
  methods: {
    ...mapActions({
      uploadInvoice: 'account/uploadInvoice',
      getProcessingInvoices: 'account/processingInvoices',
      updateInvoice: 'asset/updateAccountInvoice',
      deleteInvoice: 'asset/deleteAccountInvoice'
    }),
    async onConfirmInvoice(page) {
      const invoice = {
        invoiceNumber: this.form[page].invoiceNumber,
        startDate: this.form[page].startDate,
        endDate: this.form[page].endDate,
        invoiceDate: this.form[page].invoiceDate,
        type: this.form[page].type,
        startRead: this.form[page].startRead,
        endRead: this.form[page].endRead,
        meteredConsumption: this.form[page].meteredConsumption,
        readFactor: this.form[page].readFactor,
        climateChangeLevyRate: this.form[page].climateChangeLevyRate,
        climateChangeLevy: this.form[page].climateChangeLevy,
        dailyCharge: this.form[page].dailyCharge,
        dailyChargeTotal: this.form[page].dailyChargeTotal,
        meterPointNumber: this.form[page].meterPointNumber,
        meterSerialNumber: this.form[page].meterSerialNumber,
        netTotalCost: this.form[page].netTotalCost,
        totalTax: this.form[page].totalTax,
        totalCost: this.form[page].totalCost,
        totalUnits: this.form[page].totalUnits,
        s3Key: this.processedInvoiceS3Key
      };

      if (this.selectedAccount.type === 'gas') {
        invoice.correctedConsumption = this.form[page].correctedConsumption;
        invoice.correctionFactor = this.form[page].correctionFactor;
        invoice.calorificValue = this.form[page].calorificValue;
      }

      const updatedAccount = await this.updateAccount({ id: this.selectedAccount._id, data: { $push: { invoices: invoice } } });
      this.selectedAccount = updatedAccount;

      this.submittedInvoices.push(page);

      if (Object.keys(this.submittedInvoices).length === this.processedInvoice) {
        this.form = {};
        this.invoiceUpload = {
          status: 'preupload',
          form: null
        };
        this.polls = {};
        this.processedInvoice = [];
        this.uploadingInvoice = false;
        this.processedInvoiceS3Key = '';
      }
    }
  }
};
</script>
