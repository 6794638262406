var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('InvoiceUpload', {
    attrs: {
      "account": _vm.selectedAccount,
      "is-user": true
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }